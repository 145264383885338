import React from "react"
import { useFormContext } from "react-hook-form"
import cn from "classnames"

export default function Checkboxes({ className, children, name, label, ...delegated }) {
  const {
    register,
    formState: { errors, dirtyFields },
  } = useFormContext()

  const fieldLabel = cn("block pt-6 group text-blue-dark font-semibold", className)

  // Modify the children to pass the 'name' prop
  const modifiedChildren = React.Children.map(children, (child) => {
    return React.cloneElement(child, { name, register })
  })

  return (
    <fieldset className={fieldLabel}>
      <div className="flex justify-between items-center">
        <legend className="inline">{label}</legend>
      </div>
      <div className="flex gap-6 mt-1 items-center h-[52px]">{modifiedChildren}</div>
    </fieldset>
  )
}

export function Checkbox({ disabled, value, text, name, register }) {
  return (
    <div className="flex items-center text-blue">
      <input
        className="appearance-none mr-2 w-6 h-6 border-2 border-blue rounded-lg bg-white/20 checked:bg-green-kelly cursor-pointer relative peer"
        id={`checkbox-${value}`}
        type="checkbox"
        disabled={disabled}
        value={value}
        {...register(name)}
      />
      <svg
        className="absolute w-8 h-8 mb-1 hidden peer-checked:block pointer-events-none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
      <label htmlFor={`checkbox-${value}`}>{text ?? value}</label>
    </div>
  )
}
