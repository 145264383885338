import { useState, useEffect, useRef } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Input from "./Input"
import Select, { SelectOption } from "./Select"
import Checkboxes, { Checkbox } from "./Checkboxes"
import Textarea from "./Textarea"
import track from "utilities/track"
import Confetti from "react-confetti"
import { useWindowSize } from "react-use"
import CallToAction from "components/CallToAction"
import cn from "classnames"

function Form({
  name,
  config: { onSubmit: submitMiddleware, SuccessStatus, ErrorStatus, schema, ...restOfConfig },
  onFormSubmit,
  children,
  ...delegated
}) {
  const methods = useForm({
    ...restOfConfig,
    resolver: yupResolver(schema),
  })

  const { width, height } = useWindowSize()
  const [status, setStatus] = useState("initial")
  const [trackedBegun, setTrackedBegun] = useState(false)
  const [buttonVisible, setButtonVisible] = useState(true)

  // Reset form status back to initial state on navigation
  useEffect(() => {
    setStatus("initial")
  }, [name])

  const formRef = useRef(null)

  const onSubmit = async (values) => {
    try {
      if (typeof submitMiddleware === "function") await submitMiddleware(values)
      const payload = JSON.stringify({ page: name, values: Object.entries(values) })
      const response = await fetch("/api/processSubmission", {
        method: "POST",
        body: payload,
      })

      if (!response.ok) {
        throw new Error("Error processing submission")
      }
      setStatus("success")

      track({ event: "form_submit", name })
      if (onFormSubmit && typeof onFormSubmit === "function") onFormSubmit()
    } catch (error) {
      setStatus("failed")
      console.error("Fetch Error: ", error)
    }
  }

  useEffect(() => {
    if (methods.formState.isDirty && !trackedBegun) {
      track({ event: "form_begin", name })
      setTrackedBegun(true)
    }
  }, [methods.formState.isDirty, trackedBegun, name])

  const [confetti, setConfetti] = useState(false)
  const toggleConfetti = () => {
    setButtonVisible(false)
    track({ event: "more-confetti" })
    setConfetti((prev) => !prev)
    setTimeout(() => {
      setButtonVisible(true)
    }, 10) // small timeout to reset the animation
  }

  switch (status) {
    case "success":
      return (
        SuccessStatus || (
          <div className="form-status success text-center text-white my-24">
            <Confetti
              numberOfPieces={550}
              className="absolute left-0 bottom-0"
              recycle={confetti}
              colors={["#14294E", "#08162E", "#C4D82E", "#40B449", "#6F3493"]}
              {...{ width, height: height * 2 }}
            />
            <div
              className={cn({
                "animate-pulse": confetti,
              })}
            >
              <h2 className={cn({ "animate-bounce": confetti }, "font-bold text-blue")}>Success!</h2>
              <p className="status mb-6 max-w-sm mx-auto text-blue">Your info is on its way to our team.</p>
            </div>
            <div className="h-12">
              <CallToAction
                button
                style="tertiary"
                className={`fade-in ${buttonVisible ? "" : "hidden"} after:hidden hover:pr-6 relative z-20`}
                onClick={() => toggleConfetti()}
              >
                {confetti ? "Okay, Stop It." : "More Confetti, Please!"}
              </CallToAction>
            </div>
          </div>
        )
      )
    case "error":
      return (
        ErrorStatus || (
          <div className="form-status">
            <h2>Submission Failed</h2>
            <div className="status">An error has occurred.</div>
          </div>
        )
      )
    default:
      return (
        <div>
          <FormProvider {...methods}>
            <form
              ref={formRef}
              name={name}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={(e) => {
                e.preventDefault()
                methods.handleSubmit(onSubmit)(e)
              }}
              {...delegated}
            >
              <input type="hidden" name="form-name" value={name} />
              <label className="hidden">
                {"Don’t fill this out if you're human:"} <input name="bot-field" />
              </label>
              {children}
            </form>
          </FormProvider>
        </div>
      )
  }
}

export { Form, Input, Select, SelectOption, Checkboxes, Checkbox, Textarea }
