import { useFormContext } from "react-hook-form"
import cn from "classnames"

export default function Input({ className, name, label, ...delegated }) {
  const {
    register,
    formState: { errors, dirtyFields },
  } = useFormContext()

  const message = errors?.[name]?.message ?? false
  const dirty = dirtyFields[name] ?? false

  const fieldLabel = cn("block pt-6 group text-blue-dark font-semibold", className)

  const fieldInput = cn(
    "rounded-2xl peer font-normal border-2 w-full block p-3 bg-white/20 border-blue text-blue-dark mt-1 focus:outline-green-bright outline-offset-4",
    {
      "border-2 border-danger": message, // input is invalid, user needs to fix
    },
  )

  const errorMessage = cn("text-right text-blue-dark leading-none pointer-events-none right-0", {
    "opacity-0": !message,
    "opacity-1": message,
  })

  const errorSymbol = cn(
    "status-symbol text-l1 transition-all duration-500 text-right text-danger absolute bottom-1/2 transform translate-y-1/2 leading-none pointer-events-none right-0 -translate-x-2",
    {
      "opacity-0": !message,
      "opacity-1": !message,
    },
  )

  return (
    <label className={fieldLabel} htmlFor={name}>
      <div className="flex justify-between items-center">
        {label}
        <span aria-hidden={message} className={errorMessage}>
          {message}
        </span>
      </div>
      <div className="relative mt-2">
        <input {...delegated} className={fieldInput} {...register(name)} />
        <span aria-hidden={message} className={errorSymbol}>
          ✕
        </span>
      </div>
    </label>
  )
}
