import * as yup from "yup"
import "yup-phone-lite"
import { useRouter } from "next/router"
import CallToAction from "components/CallToAction"
import { Form, Input, Select, SelectOption, Textarea, Checkboxes, Checkbox } from "./Form"
import LinedCircle from "/public/assets/decorative-patterns/lined-circle.svg"
import PlusPattern from "/public/assets/decorative-patterns/plus-pattern.svg"
import DoubleHeading from "components/DoubleHeading"
import getBasePath from "utilities/getBasePath"
import { useMemo, useRef, useEffect, useState } from "react"

export default function FormSection({ heading, heading2, eyebrow }) {
  const required = "Required"
  const nameValidation = yup.string().required(required)
  const { asPath } = useRouter()
  const basePath = useMemo(() => getBasePath(asPath), [asPath])
  const containerRef = useRef(null)
  const [status, setStatus] = useState("initial")

  const commonSchema = {
    First: nameValidation,
    Last: nameValidation,
    Email: yup.string().email("Invalid Email").required(required),
    Phone: yup.string().phone("US", "Invalid Phone").required(required),
    Budget: yup.string().required(required),
  }

  const schemaWithZip = yup.object().shape({
    ...commonSchema,
    Zip: yup
      .string()
      .required(required)
      .matches(/^[0-9]+$/, "Invalid Zip")
      .min(5, "Must be 5 digits")
      .max(5, "Must be 5 digits"),
  })

  const schemaWithoutZip = yup.object().shape({
    ...commonSchema,
  })

  const formConfig = {
    mode: "onTouched",
    schema: basePath === "/contact" || basePath === "/national-sales" ? schemaWithZip : schemaWithoutZip,
  }

  const handleFormSubmit = () => {
    setStatus("success")
  }

  useEffect(() => {
    // Scroll to top of form container after successful submission
    if (status === "success" && containerRef.current) {
      const offsetTop = containerRef.current.offsetTop

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      })
    }
  }, [status])

  return (
    <section
      ref={containerRef}
      id="contact-form"
      className="w-full relative pt-16 2xl:pb-8 bg-kelly-to-lime overflow-hidden section"
    >
      <div className="relative">
        <LinedCircle className="absolute z-0 -top-16 -right-12 size-36 lg:size-48 lg:-top-32 lg:-right-16 xl:-top-36 xl:-right-20 xl:size-64 fill-green-kelly" />
        <div className="flex justify-center max-w-screen-xl 2xl:max-w-screen-2xl mx-auto">
          <div className="text-center max-w-2xl pb-12 relative z-10">
            {eyebrow && <span className="eyebrow eyebrow-2 text-purple-bright">{eyebrow}</span>}
            <DoubleHeading className="px-12 lg:container mb-1 text-blue" heading={heading} heading2={heading2} />
          </div>
        </div>
      </div>
      <Form
        onFormSubmit={handleFormSubmit}
        name={`${basePath ?? `form`}`}
        config={formConfig}
        className="max-w-screen-xl 2xl:max-w-screen-2xl mx-auto relative z-10 pb-16 container"
      >
        <div className="flex flex-col w-full sm:flex-row sm:flex-wrap">
          <Input
            className="sm:pr-6 sm:w-1/2 lg:w-1/4"
            autoComplete="given-name"
            name="First"
            label="First name"
            type="text"
          />
          <Input
            className="lg:pr-6 sm:w-1/2 lg:w-1/4"
            autoComplete="family-name"
            name="Last"
            label="Last name"
            type="text"
          />
          <Input
            className="sm:pr-6 sm:w-1/2 lg:w-1/4"
            autoComplete="company"
            name="Company"
            label="Company"
            type="text"
          />
          <Select defaultValue="" className="sm:w-1/2 lg:w-1/4" name="Budget" label="Annual Marketing Budget">
            <SelectOption value="" disabled text="Select..." />
            <SelectOption value="Under $36,000" />
            <SelectOption value="$36,000 - $150,000" />
            <SelectOption value="$150,000+" />
          </Select>
          <div className="w-full lg:w-1/2 flex flex-wrap">
            <Input
              className="sm:pr-6 w-full sm:w-1/2"
              autoComplete="email"
              name="Email"
              label="Email address"
              type="text"
            />
            <Input
              className="lg:pr-6 w-full sm:w-1/2"
              autoComplete="tel"
              name="Phone"
              label="Phone number"
              type="tel"
            />
            {basePath === "/contact" || basePath === "/national-sales" ? (
              <Input className="sm:pr-6 w-full sm:w-1/2" autoComplete="zip" name="Zip" label="Zip Code" type="text" />
            ) : null}
            <Checkboxes
              className="lg:pr-6 w-full sm:w-1/2"
              name="Contact Preference"
              label="How do you prefer to connect?"
            >
              <Checkbox value="Email" />
              <Checkbox value="Phone" />
            </Checkboxes>
          </div>

          <Textarea
            className="flex flex-col w-full lg:w-1/2 mx-auto"
            name="Message"
            label="What can we help you with?"
            type="text"
          />
        </div>
        <div className="mt-8 flex justify-center">
          <CallToAction style="tertiary" button className="mt-5">
            Connect With Us
          </CallToAction>
        </div>
      </Form>
      <PlusPattern className="z-0 absolute fill-green-lime size-64 -left-20 -bottom-24 xl:-bottom-40 xl:-left-32 lg:size-72 xl:size-96 " />
    </section>
  )
}
